#earth-wrapper{
    width: 50vw;
    min-width: 400px;
    height: 50vw;
    min-height: 400px;
    position: fixed;
    right: 0px;
    bottom: 0;
    filter: blur(4px);
    opacity: 0.7;
    transition: all 1s;
    overflow: hidden;
    z-index: -10;

    video, #earth-canvas{
        width: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}
#earth-wrapper:hover{
    filter: blur(1px);
}

@media (max-width: 500px) {
    #earth-wrapper{
        right: -20%;
    }
}

#main-title{
    font-weight: 600;
    max-width: 100%;
}
#sub-title{
    font-weight: bolder;
}
#landing-sec{
    padding-top: min(10vh, 30rem);
    min-height: 100vh;
}

#description{
    max-width: 500px;
    width: 100%;
    position: relative;
    padding: 2rem 3rem 2rem 3rem;
    background-color: var(--glow-color);
}
#description>p{
    margin: 0;
}

@media (max-width: 996px) {
    #description{
        margin: auto;
        padding: 2rem;
    }
}

#learn-more-btn{
    position: absolute;
    bottom: 1rem;
    right: 2rem;
}

#btns-wrapper{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    /* border: 1px solid var(--glow-dim-color); */
    width: max-content;
    /* padding: 1rem;
    border-radius: 0.5rem 10rem 10rem 10rem; */
}
@media (max-width: 500px) {
    #main-title{
        margin: auto;
    }
    #btns-wrapper{
        flex-direction: column;
        border-radius:46px;
        margin-left: auto;
        margin-right: auto;
    }
    #landing-sec>h1, #landing-sec>h2{
        text-align: center;
    }
}

.cta{
    border: 1px solid transparent;
}

#line-sec{
    height: 50rem;
    /* border-top: 2px solid var(--theme-hover);
    border-bottom: 2px solid var(--theme-hover); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: min(5rem, 5vw);
    margin: 4rem auto;
}

#line-wrapper{
    position: relative;
    width: 100%;
    margin-top: 5rem;
}

#line-link{
    margin: auto;
    display: block;
    height: max-content;
    max-width: 30%;
    width: max-content;
}

.line-logo{
    width: 100%;
    max-width: 300px;
    min-width: 100px;
    z-index: 5;
}

.line-glow{
    filter: blur(5px) invert(71%) sepia(69%) saturate(5374%) hue-rotate(108deg) brightness(101%) contrast(95%);
    opacity: 0.5;
    transition: all 0.5s;
    
    z-index: 3;
}

#line-title{
    font-size: 3rem;
    font-weight: 600;
}
@media (max-width: 768px) {
    #line-title{
        font-size: 2rem;
        font-weight: 600;
    }
}

#line-link:hover~.line-glow{
    filter: blur(20px) invert(71%) sepia(69%) saturate(5374%) hue-rotate(108deg) brightness(101%) contrast(95%);
}
#line-link:hover~.line-logo{
    opacity: 0.9;
}

#settlement-card{
    max-width: 600px;
    margin: auto;
    padding: min(5rem, 10vw);
    margin: 4rem auto;
    background-color: var(--theme-dark);
}

#settlement-dis{
    margin-top: 3rem;
    font-size: 1.2rem;
}

#cards-sec{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    padding: 0;
}

.qa-card{
    padding: min(2rem, 5vw) !important;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    justify-self: center;
    background-color: var(--theme-dark);
}

.qa-title{
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.qa-img{
    object-fit: contain;
    object-position: bottom;
    margin: 2rem 2rem 4rem 2rem;
    width: calc(100% - 4rem);
}

#delivery-sec{
    display: flex;
    flex-direction: column;
    padding: 10rem 0rem;
    margin: 10rem auto;
    gap: 5rem;
    /* border-top: 2px solid var(--theme-hover);
    border-bottom: 3px solid var(--theme-hover); */
}

#delivery-cards{
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.delivery-card{
    width: 100%;
    transform: scale(80%);
    transition: all 0.8s;
    padding: 5rem 2rem;
    /* margin: auto; */
    box-shadow: 0px 2px 16px 8px var(--shadow-color);
}
.delivery-card-focus{
    transform: scale(100%);
    box-shadow: 0px 2px 16px 8px var(--shadow-color);
}
.delivery-card-focus>h3, .delivery-card-focus>p{
    color: var(--glow-color)
    
}
.delivery-card-focus#profit-card{
    background-color: #FF5858;
}
.delivery-card-focus#loss-card{
    background-color: #00CE53;
}
#delivery-amount{
    border: 1px solid var(--theme-hover);
    border-radius: 2rem;
    padding: 2rem 3rem 3rem 3rem;
    width: max-content;
    margin: auto;
}
#delivery-amount{
    font-size: 1.3rem;
    text-wrap: wrap;
    width: 100%;
    display: block;
}
#delivery-amount>legend{
    color: var(--theme-color);
    font-weight: 500;
}

#cta-bottom{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    margin: 5rem auto 8rem auto;
    width: 100%;
}

.arrow-btn{
    display: flex;
    margin: 1rem;
}