#contact-form{
    display: grid;
    grid-template-columns: minmax(50px, auto) 1fr;
    grid-template-rows: auto;
    /* grid-template-rows: auto repeat(4, 3rem) repeat(2, 4rem) repeat(17, 3rem) auto 3rem 3rem; */
    grid-template-areas: 
        "title title"
        "platform platform-input"
        "name name-input"
        "phone phone-input"
        "tel tel-input"
        "address address-input"
        "id-address id-address-input"
        "email email-input"
        "hr1 hr1"
        "com-name com-name-input"
        "com-tel com-tel-input"
        "com-address com-address-input"
        "hr2 hr2"
        "emerg-name emerg-name-input"
        "emerg-tel emerg-tel-input"
        "emerg-address emerg-address-input"
        "hr3 hr3"
        "file file-input"
        "file file-input"
        "file file-input"
        "file file-input"
        "hr4 hr4"
        "contact-time contact-time"
        "hr5 hr5"
        "message message-textarea"
        "hr6 hr6"
        "... rule-read"
        "... reCAP"
        "submit submit";
    gap: 1.5rem 2rem;
    margin: 2rem auto;
    padding: 2rem 0rem;
    max-width: 1024px;
    background-color: var(--glass-color);
    backdrop-filter: blur(5px);
}

label{
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.2rem;

    span{
        font-size: 0.9rem;
    }
}
#title{
    grid-area: title;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
#title>h1{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
#title>p{
    margin: 0rem;
}
.address{
    display: grid;
    grid-template-columns: 1fr auto;
    height: max-content;
}
.address>fieldset{
    margin: 0rem 1rem;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 1fr;
    justify-content: center;
    align-items: center;
    gap: 0.2rem 0.5rem;
}
.address>fieldset>input{
    grid-row: 2/3;
    width: 100%;
    height: 100%;
    padding: 0rem;
    margin: auto;
}
.address>fieldset>label{
    grid-row: 1/2;
    margin: 0rem;
    text-align: center;
    font-size: 1.2rem;
}
.address>input{
    width: 100%;
}
.g-recaptcha{
    /* grid-area: reCAP; */
    grid-column: 2/3;
}
#rule-read-field{
    /* grid-area: rule-read; */
    grid-column: 2/3;
}
.checkbox{
    aspect-ratio: 1/1;
    width: 40px;
    height: 40px;
}
#submit{
    /* grid-area: submit; */
    grid-column: 1/3;
    max-width: 10rem;
    margin: 2rem auto;
}
hr{
    grid-column: 1/3;
    height: 0px;
    width: 100%;
    border: 1px solid var(--theme-hover);
    margin: 1rem auto;
}
fieldset{
    border: none;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    label{
        margin-right: 2rem;
        cursor: pointer;
    }
}
#rule-link{
    color: var(--theme-color);
    margin: auto 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.fieldset-item{
    display: flex;
    flex-direction: row;
    width: max-content;
}
.go-btn{
    display: block;
    margin: 2rem auto 2rem auto;
    text-wrap: wrap;
    max-width: 100%;
}

@media (max-width: 996px) {
    #contact-form{
        padding: 2rem 0rem;
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        
    }

    .address{
        display: grid;
        grid-template-columns: 1fr !important;
    }
    .address>fieldset{
        height: max-content;
        margin: 1rem 0rem;
        display: grid;
        
        grid-template-columns: repeat(4, auto);
        grid-template-rows: auto 1fr;
        justify-content: left;
        align-items: center;
        gap: 0.2rem 0.5rem;
    }
    .address>fieldset>input{
        grid-row: 1/2;
        width: auto;
        height: 100%;
        padding: 0rem;
        margin: auto;
    }
    .address>fieldset>label{
        margin: 0rem;
        text-align: center;
    }

    .g-recaptcha{
        margin: auto;
    }
    #rule-read-field{
        margin: auto;
    }
    fieldset{
        border: none;
        height: 100%;
        display: flex;
        align-items: center;
    }

    #contact-form>label{
        font-weight: 600;
        font-size: 1.5rem;
        margin-top: 2rem;
    }
}