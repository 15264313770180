header{
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 100;
    box-shadow: 0px 0px 4px 2px var(--shadow-color);
    background-color: var(--glow-color);
    /* background-color: #353865; */
}
.stander-header{

    width: 100%;
    min-height: 6rem;
    padding: 3rem 2rem 2rem 2rem;
    display: grid;
    grid-template-columns: auto 1fr auto auto auto auto;
    grid-template-areas: "logo nav anti scta cta loca";
    gap: 0rem min(2vw, 2rem);
    
    /* backdrop-filter: blur(10px); */
    /* margin: 2rem auto 1rem auto; */
    transition: all 1s;
    z-index: 100;
    position: relative;
}


#brand-name{
    height: min-content;
    width: max-content;
    color: var(--title);
    font-weight: 600;
    font-size: 1.2rem;
    text-decoration-line: none;
    margin: auto;   
    justify-self: center;
    
    animation: text-glowing 3s infinite alternate;
}

/* #brand-name::after{
    display: block;
    content: " ";
    width: 130%;
    height: 36px;
    background: url("../../../public/pictures/unite-deco.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);

    position: absolute;
    z-index: -1;
} */
@keyframes text-glowing{
    0%  {text-shadow: 0px 0px 5px #ffffff;}
    25%  {text-shadow: 0px 0px 10px #ffffff;}
    75%  {text-shadow: 0px 0px 7px #ffffff;}
    100%  {text-shadow: 0px 0px 15px #ffffff;}
}
#brand-wrapper{
    grid-area: logo;
    gap: 1rem;
    /* box-shadow: 0px 0px 4px var(--glow-color); */
    transition: all 0.8s;
    width: max-content;
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
}
#brand-wrapper:hover{
    background-color: var(--glass-color);
    box-shadow: 0px 0px 10px 0px var(--glow-color);
    backdrop-filter: blur(30px);
}
@media (max-width: 768px) {
    #brand-wrapper{

    }
}
.header-logo{
    width: 48px;
}
.logo-glow{
    opacity: 0.7;
    z-index: 5;
    animation: logo-glow 3s infinite alternate;
}
#brand-wrapper:hover .logo-glow{
    opacity: 1;
}

#anti-scam-link{
    right: max(5vw, 36px);
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--de-text);
    margin: 0.5rem;

    color: var(--de-text);
}
#anti-scam-link:hover{
    color: var(--theme-hover);
    border-bottom: 1px solid var(--theme-hover);
}
#anti-scam-link>p{
    margin: 0px;
    font-weight: 600;
    display: block;
    height: max-content;
}

#scta{
    position: relative;
    grid-area: scta;
    display: flex;
    align-items: center;
    text-shadow: 0px 0px 4px var(--glow-color);
    cursor: pointer;
    gap: 10px;
    margin-left: 48px;
}
#scta::before{
    display: block;
    content: " ";
    width: 36px;
    height: 36px;
    background: url("./LINE_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-120%, -50%);
}
#scta>p{
    margin: 0;
}
#home-link{
    display: none;
    text-shadow: 0px 0px 4px var(--glow-color);
    cursor: pointer;
    font-size: 1.2rem;
}


@keyframes logo-glow{
    0% {filter: blur(10px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}
    25% {filter: blur(2px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}
    75% {filter: blur(8px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}
    100% {filter: blur(5px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}    
}

#apply-now{
    grid-area: cta;
    text-shadow: 0px 0px 4px var(--glow-color);
    border: 1px solid transparent;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

#menu{
    max-width: 30px;
    display: none;
}

@media (max-width: 1250px) {
    .stander-header{
        grid-template-columns: auto 1fr auto;
        grid-template-areas: "logo ... nav";
        padding: 1rem 2rem;
    }
    #brand-wrapper{
        
    }
    .header-logo{

    }
    #brand-name{
        

    }
    .header-nav{
        flex-direction: column;
        z-index: 900;
    }
    #menu{
        display: initial;
        z-index: 900;
    }
    #selector-container{
        display: none;
        position: fixed;
        top: 3rem;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 900;
        height: 4rem;
    }
    .nav-item{
        display: none;
        position: fixed;
        width: max-content;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 900;
    }
    #nav-item-1{
        top: 22rem;
    }
    #nav-item-2{
        top: 26rem;
    }
    #anti-scam-link{
        display: none;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 32rem;
        z-index: 900;
        width: max-content;
        padding: 0.5rem 1rem;
        margin: 0;
    }
    #scta{
        display: none;
        position: fixed;
        left: 50%;
        margin-left: 0px;
        transform: translate(-50%, 0);
        top: 17rem;
        z-index: 900;
    }
    #home-link{
        display: none;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 11.5rem;
        padding: 1rem 1rem;
        z-index: 900;
    }
    #apply-now{
        display: none;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 8rem;
        z-index: 900;
        
        
    }
    #backdrop{
        display: none;
        position: fixed;
        height: 100vh;
        width: 100vw;
        min-width: 300px;
        top: 0px;
        right: 0px;
        background-color: var(--backdrop-color);
        backdrop-filter: blur(10px);
        z-index: 20;
    }
    .displayFlex{
        display: flex !important;
    }
}
