#error-title{
    font-size: 4rem;
    margin-top: 20dvh;
    margin-bottom: 0.5rem;
}

#error-text{
    font-size: 1.5rem;
    margin-bottom: 3rem;
}

#back-btn{
    width: max-content;
    margin: auto;
    box-shadow: 0px 0px 10px 5px var(--theme-color);
}
#back-btn:hover{
    box-shadow: 0px 0px 20px 10px var(--theme-color);
}

.error-sec{
    display: flex;
    flex-direction: column;
}