#stock-login-tilte{
    margin: auto;
}

#login-sec{
    container-name: form-wrap;
    container-type: inline-size;
    min-height: 70vh;
}

#login-form{
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px 20px;
    margin: min(6vh, 100px) auto;
    max-width: 800px;
}

#login-form>input, #login-form>div>input{
    width: 100%;
}

#login-form>button{
    max-width: 150px;
    margin: auto;
}

#memo-checkboxes{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 2rem 0;
}

.checkbox-wrapper{
    display: flex;
}

@container form-wrap (width < 500px) {
    #login-form{
        gap: 10px 10px;
    }
    #login-form>label{
        margin-top: 20px;
    }
    #login-form>button{
        max-width: 150px;
        margin: auto;
    }
}