#standard-footer{
    background-image: var(--theme-gradient);
    box-shadow: 0px 0px 10px 5px var(--shadow-color);
    width: 100%;
    position: absolute;
    margin-top: 24px;
    color: var(--glow-color);
}

#footer-div{
    padding: 5rem 2rem;
}

#footer-div>h2{
    color: var(--glow-color);
}

#footer-div>p{
    color: var(--glow-color);
}