.rules-dia{
    height: max-content;
    max-height: 80%;

}
#agree-btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto;
}
#agree-btns>button{
    max-width: 100%;
    text-wrap: wrap;
    flex-grow: 1;
}
#agree{
    text-shadow: 0px 0px 4px var(--glow-color);
}
#disagree{
    background-color: var(--glow-color);
    color: var(--de-color);
}

@media (max-width: 768px) {
    .rules-dia{
        width: 95%;
    }
    #agree-btns>button{
        border-radius: 10px;
    }    
}